import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthRoute } from "./routes/authRoute";
import { ActivateAccount } from "./features/auth/views/activate";
import { Login } from "./features/auth/views/login";
import { AppRoute } from "./routes/appRoute";
import { UserList } from "./features/users/views";
import { UserDetails } from "./features/users/views/show";
import { UserCreate } from "./features/users/views/create";
import { UserEdit } from "./features/users/views/edit";
import { AdminRoute } from "./routes/adminRoute";
import { BonList } from "./features/bons/views";
import { BonCharge } from "./features/bons/views/charge";
import { PurchaseWithBon } from "./features/bons/views/purchase";
import { BonImportList } from "./features/bonImports/views";
import { Statistics } from "./features/statistics/views/statistics";
import { StatisticTypeEnum } from "./features/statistics/redux/statisticTypes";
import { SalonList } from "./features/salon/views";
import { SalonEdit } from "./features/salon/views/edit";
import { SalonCreate } from "./features/salon/views/create";
import { ProfileShow } from "./features/auth/views/show";
import { ProfileEdit } from "./features/auth/views/edit";
import { ConfigSelectSalon } from "./features/configs/views/selectSalon";
import { ClientDashboard } from "./features/clients/views/dashboard";
import { ClientLayout } from "./features/components/clientLayout/layout";
import { Error } from "./features/components/error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ClientLayout />}>
          <Route element={<ClientDashboard />} path={"/"} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route element={<ActivateAccount />} path={"/activate/:token"} />
          <Route element={<Login />} path={"/login"} />
        </Route>
        <Route element={<AppRoute />}>
          <Route element={<AdminRoute />}>
            <Route element={<UserList />} path={"/panel/users"} />
            <Route element={<UserDetails />} path={"/panel/users/:id"} />
            <Route element={<UserCreate />} path={"/panel/users/create"} />
            <Route element={<UserEdit />} path={"/panel/users/:id/edit"} />
            <Route element={<BonList />} path={"/panel/bons"} />
            <Route element={<BonImportList />} path={"/panel/bon-imports"} />
            <Route element={<SalonList />} path={"/panel/salons"} />
            <Route element={<SalonEdit />} path={"/panel/salons/:id/edit"} />
            <Route element={<SalonCreate />} path={"/panel/salons/create"} />
          </Route>
          <Route element={<BonCharge />} path={"/panel/bons/charge"} />
          <Route element={<BonCharge />} path={"/panel"} />
          <Route element={<PurchaseWithBon />} path={"/panel/bons/purchase"} />
          <Route element={<ProfileShow />} path={"/panel/profile"} />
          <Route element={<ProfileEdit />} path={"/panel/profile/edit"} />
          <Route
            element={<ConfigSelectSalon />}
            path={"/panel/hide-configuration"}
          />
          <Route
            element={
              <Statistics
                statsType={StatisticTypeEnum.BUY}
                title="statistics_by_charge"
              />
            }
            path="/panel/statistics/by-charge"
          />
          <Route
            element={
              <Statistics
                statsType={StatisticTypeEnum.SELL}
                title="statistics_by_sell"
              />
            }
            path="/panel/statistics/by-sell"
          />
        </Route>
        <Route
          path={"*"}
          element={
            <Error
              error={{
                errorCode: 404,
                errorMessage: "errors.not_found",
              }}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
