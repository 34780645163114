import { bonCardApi } from "./../../../app/bonCardApi";
import {
  GetStatisticPropsInterface,
  GetStatisticPropsInterfaceWithPagination,
  GetStatisticSuccessResponse,
} from "./statisticTypes";
import _ from "lodash";

export const statisticApi = bonCardApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<
      GetStatisticSuccessResponse,
      GetStatisticPropsInterfaceWithPagination
    >({
      query: (props) => ({
        url: "/api/boncards-transactions",
        params: _.pickBy(
          props,
          (value) => value !== undefined && value !== null && value !== ""
        ),
      }),
      providesTags: (result, error, { stats_type }) => [
        { type: "Statistics", id: stats_type },
      ],
    }),
    getExportStatistics: builder.query<void, GetStatisticPropsInterface>({
      query: (props) => ({
        url: "/api/boncards-transactions",
        params: _.pickBy(
          props,
          (value) => value !== undefined && value !== null && value !== ""
        ),
        responseHandler: async (response) =>
          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          ),
        cache: "no-cache",
      }),
    }),
  }),
});

export const {
  useGetStatisticsQuery,
  useLazyGetExportStatisticsQuery,
  useLazyGetStatisticsQuery,
} = statisticApi;
