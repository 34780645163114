import { BreadCrumbInterface } from "../features/components/breadcrumbs/breadcrumbs.types";
import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface BreadcrumbStateInterface {
  breadcrumbs: BreadCrumbInterface[];
}

const initialState: BreadcrumbStateInterface = {
  breadcrumbs: [],
};

const breadcrumbState = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<BreadCrumbInterface[]>) => {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setBreadcrumbs } = breadcrumbState.actions;

export default breadcrumbState.reducer;

export const getBreadcrumbs = createDraftSafeSelector(
  (state: RootState) => state.breadcrumb,
  (breadcrumbState) => breadcrumbState.breadcrumbs
);
