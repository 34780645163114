import { useTranslation } from "react-i18next";
import { useGetPublicSalonsQuery } from "../../salon/redux/salonApi";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";
import { useBreadcrumbs } from "../../../app/hooks";

export const ConfigSelectSalon = () => {
  const { t } = useTranslation();
  const { data } = useGetPublicSalonsQuery();
  const [salon, setSalon] = React.useState<string | undefined>(undefined);

  useBreadcrumbs(breadcrumbs);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (salon) {
      localStorage.setItem("selectedSaloon", salon);
      toast.success(t("salon_selected"));
    }
  };

  useEffect(() => {
    const selectedSaloon = localStorage.getItem("selectedSaloon");

    if (selectedSaloon) {
      setSalon(selectedSaloon);
    }
  }, [data]);

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("mother_salon")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <form onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col} md="2">
                <Form.Label>{t("salon")}</Form.Label>
                <Form.Select
                  className="form-control"
                  name="salon"
                  value={salon}
                  onChange={(e) => setSalon(e.target.value)}
                >
                  <option value="">{t("select_salon")}</option>
                  {data?.map((salon) => (
                    <option key={salon.id} value={salon.id}>
                      {salon.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mt-4">
              <Col>
                <Button variant="primary" type="submit">
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/hide-configuration",
    label: "menu.select_salon",
  },
];
