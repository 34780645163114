import React, { useState } from "react";
import { Sidebar } from "./sidebar";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/typography.css";
import "../../../assets/css/style.css";
import "../../../assets/css/responsive.css";
import { Navbar } from "./navbar";
import "react-toastify/dist/ReactToastify.css";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = (props: LayoutProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    const wrapperMenu = document.querySelector(".wrapper-menu");

    if (wrapperMenu) {
      if (showMenu) {
        wrapperMenu.classList.remove("open");
        document.querySelector("body")?.classList.remove("sidebar-main");
        setShowMenu(false);
      } else {
        wrapperMenu.classList.add("open");
        document.querySelector("body")?.classList.add("sidebar-main");
        setShowMenu(true);
      }
    }
  };

  return (
    <React.Fragment>
      <Sidebar onToggleSidebar={toggleMenu} />
      <Navbar onToggleSidebar={toggleMenu} />
      <div id="content-page" className="content-page">
        <div className="container-fluid">{props.children}</div>
      </div>
    </React.Fragment>
  );
};
