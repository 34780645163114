import React, { useEffect, useState } from "react";
import errorImage from "./../../assets/images/error/01.png";
import { useTranslation } from "react-i18next";

type Props = {
  error?: {
    errorCode: number;
    errorMessage: string;
  };
};

export const Error = (props: Props) => {
  const { t } = useTranslation();
  const [errorCode, setErrorCode] = useState(500);
  const [errorMessage, setErrorMessage] = useState(
    "errors.internal_server_error"
  );

  useEffect(() => {
    if (props.error) {
      setErrorCode(props.error.errorCode);
      setErrorMessage(props.error.errorMessage);
    }
  }, [props.error]);

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-sm-12 text-center">
              <div className="iq-error">
                <h1>{errorCode}</h1>
                <h4 className="mb-0">{t(errorMessage)}</h4>
                <a className="btn btn-primary mt-3" href="/panel/bons/charge">
                  <i className="ri-home-4-line"></i>
                  {t("back_to_home")}
                </a>
                <img
                  src={errorImage}
                  className="img-fluid iq-error-img"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
