import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserQuery, useUpdateUserMutation } from "../redux/userApi";
import { Loading } from "../../components/loading";
import React, { useEffect } from "react";
import { UserForm } from "./parts/form";
import { SaveUserPropsInterface } from "../redux/userTypes";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";

export const UserEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data: userData, isLoading } = useGetUserQuery(id!);
  const [updateUser, { isSuccess, isError, error }] = useUpdateUserMutation();
  const navigate = useNavigate();

  const onSubmit = (data: SaveUserPropsInterface) => {
    updateUser({
      id: id!,
      ...data,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("user_updated_successfully"));
      navigate("/panel/users");
    }
  }, [isSuccess]);

  if (isLoading) return <Loading />;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("editing_user")}</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <UserForm
                isError={isError}
                error={error}
                onSubmit={onSubmit}
                data={userData}
              />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
