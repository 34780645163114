import { useTranslation } from "react-i18next";
import {
  SaveUserPropsInterface,
  UserInterface,
  UserRoleEnum,
} from "../../redux/userTypes";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useGetPublicSalonsQuery } from "../../../salon/redux/salonApi";

type UserFormProps = {
  onSubmit: (data: SaveUserPropsInterface) => void;
  error: any;
  isError: boolean;
  data?: UserInterface;
};

export const UserForm = (props: UserFormProps) => {
  const { onSubmit, error, isError, data } = props;
  const { t } = useTranslation();
  const { data: salonsData = [] } = useGetPublicSalonsQuery();

  const schema: yup.Schema<SaveUserPropsInterface> = useMemo(() => {
    return yup.object({
      name: yup.string().required(t("validations.field_is_required")),
      surname: yup.string().required(t("validations.field_is_required")),
      email: yup
        .string()
        .email(t("validations.field_must_be_email"))
        .required(t("validations.field_is_required")),
      salon_id: yup.number().required(t("validations.field_is_required")),
      role: yup
        .mixed<UserRoleEnum>()
        .required(t("validations.field_is_required")),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<SaveUserPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      salon_id: salonsData.at(0)?.id,
      role: UserRoleEnum.EMPLOYER,
    },
  });

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("surname", data.surname);
      setValue("email", data.email);
      setValue("salon_id", data.salon_id);
      setValue("role", data.role);
    }
  }, [data]);

  useEffect(() => {
    if (error && "data" in error && error.status === 422 && error.data) {
      const errorResponse: any = error.data;
      Object.entries(errorResponse.errors).forEach((field) => {
        const parameter = field[0] as keyof SaveUserPropsInterface;

        setError(parameter, {
          type: "validate",
          message: errorResponse.errors[field[0]][0],
        });
      });
    }
  }, [isError, error, setError]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("first_name")}</Form.Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...field}
                  placeholder={t("placeholders.enter_first_name")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("last_name")}</Form.Label>
            <Controller
              name="surname"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.surname}
                  {...field}
                  placeholder={t("placeholders.enter_surname")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.surname?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("email")}</Form.Label>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="email"
                  isInvalid={!!errors.email}
                  {...field}
                  placeholder={t("placeholders.enter_email")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("role")}</Form.Label>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Form.Select
                  className="form-control"
                  isInvalid={!!errors.role}
                  {...field}
                >
                  <option value={UserRoleEnum.EMPLOYER}>{t("employer")}</option>
                  <option value={UserRoleEnum.ADMIN}>
                    {t("administrator")}
                  </option>
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.role?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("salon")}</Form.Label>
            <Controller
              name="salon_id"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Form.Select
                  className="form-control"
                  isInvalid={!!errors.salon_id}
                  onChange={(e) => onChange(e.target.value ?? null)}
                  value={value ?? ""}
                  name={name}
                >
                  <option>{t("chose_salon")}</option>
                  {salonsData.map((salon) => {
                    return (
                      <option value={salon.id} key={salon.id}>
                        {salon.name}
                      </option>
                    );
                  })}
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.salon_id?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button variant="success" type="submit">
              <FontAwesomeIcon icon={faCheck} /> {t("save")}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
