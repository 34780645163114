import { useTranslation } from "react-i18next";
import { useLazyGetBonsQuery } from "../redux/bonApi";
import React, { useEffect, useMemo, useState } from "react";
import { BonInterface, BonStatusEnum } from "../redux/bonTypes";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Col, Form, Row } from "react-bootstrap";
import { Pagination } from "../../components/pagination";
import { ActionColumn } from "./parts/actionDropdown";
import _ from "lodash";
import { useBreadcrumbs } from "../../../app/hooks";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";

export const BonList = () => {
  const { t } = useTranslation();
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [query, setQuery] = useState<string>();
  const [status, setStatus] = useState<BonStatusEnum>();
  const [getBons, { data: getBonsData, isLoading }] = useLazyGetBonsQuery();

  useBreadcrumbs(breadcrumbs);

  const [data, setData] = useState<BonInterface[]>([]);

  useEffect(() => {
    if (getBonsData) {
      setData(getBonsData.items);
    }
  }, [getBonsData]);

  useEffect(() => {
    getBons({
      page: pageIndex + 1,
      per_page: pageSize,
    });
  }, []);

  useEffect(() => {
    fetchBons();
  }, [status, query, pageIndex, pageSize]);

  const fetchBons = _.debounce(() => {
    return getBons({
      page: pageIndex + 1,
      per_page: pageSize,
      query,
      status,
    });
  }, 1000);

  const onStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value);
    if (value in BonStatusEnum) {
      setStatus(value);
    } else {
      setStatus(undefined);
    }
  };

  const columnHelper = createColumnHelper<BonInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.number, {
        id: "number",
        header: t("bon_number"),
      }),
      columnHelper.accessor((row) => t(`bon_status.${row.status}`), {
        id: "status",
        header: t("status"),
      }),
      columnHelper.accessor(
        (row) => (row.type !== null ? t(`bon_types.${row.type}`) : "-"),
        {
          id: "type",
          header: t("bon_type"),
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.amount
            ? new Intl.NumberFormat("pl", {
                style: "currency",
                currency: "PLN",
              }).format(row.amount)
            : "-",
        {
          id: "amount",
          header: t("amount"),
        }
      ),
      columnHelper.accessor((row) => row.valid_until ?? "-", {
        id: "valid_until",
        header: t("valid_until"),
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => <ActionColumn bon={row.original} />,
      }),
    ],
    [columnHelper, t]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: getBonsData?.info.total_items,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("bons")}</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <Row className="pb-4">
                <Col md={2}>
                  <Form.Control
                    type="text"
                    placeholder={t("bon_number")}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </Col>
                <Col md={2}>
                  <Form.Select
                    className="form-control"
                    onChange={onStatusChange}
                  >
                    <option value={-1}>{t("chose_status")}</option>
                    {Object.values(BonStatusEnum)
                      .filter((v) => !isNaN(Number(v)))
                      .map((status, index) => {
                        return (
                          <option key={index} value={status}>
                            {t(`bon_status.${status}`)}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
              <table className="table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={getBonsData?.info.total_pages ?? 0}
                  table={table}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/bons",
    label: "menu.bon_list",
  },
];
