import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ActivateAccountPropsInterface,
  LoginPropsInterface,
  UpdateAccountPropsInterface,
} from "./authTypes";
import { store } from "../../app/store";

const url = process.env.REACT_APP_BACKEND_URL;

export const updateAuthAccountRequest = createAsyncThunk(
  "auth/updateAccount",
  async (data: UpdateAccountPropsInterface, thunkAPI) => {
    const token = store.getState().auth.bearerToken;
    const response = await fetch(`${url}/api/auth/update-account`, {
      method: "PUT",
      body: JSON.stringify({
        password: data.password,
        password_confirmation: data.passwordConfirmation,
        old_password: data.currentPassword,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  }
);

export const loginRequest = createAsyncThunk(
  "auth/login",
  async (data: LoginPropsInterface, thunkAPI) => {
    const response = await fetch(`${url}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.json();
  }
);

export const activateAccountRequest = createAsyncThunk(
  "auth/activateAccount",
  async (data: ActivateAccountPropsInterface & { token: string }, thunkAPI) => {
    const response = await fetch(`${url}/api/activate`, {
      method: "POST",
      body: JSON.stringify({
        password: data.password,
        password_confirmation: data.passwordConfirmation,
        token: data.token,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    return response.json();
  }
);

export const logoutRequest = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    const token = store.getState().auth.bearerToken;
    const response = await fetch(`${url}/api/auth/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.json();
  }
);
