import { useTranslation } from "react-i18next";
import { useGetBonQuery } from "../redux/bonApi";
import { Modal } from "react-bootstrap";
import { formatDate } from "../../../app/helpers";
import React from "react";
import { CloseButtonModal } from "../../components/closeModalButton";

type BonDetailsProps = {
  id: number;
  show: boolean;
  onHide: () => void;
};

export const BonDetails = (props: BonDetailsProps) => {
  const { id, show, onHide } = props;
  const { data: bonData } = useGetBonQuery(id);
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{t("bon_details")}</Modal.Title>
        <CloseButtonModal onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        {bonData && (
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>{t("bon_number")}</th>
                <td>{bonData.number}</td>
              </tr>
              <tr>
                <th>{t("status")}</th>
                <td>{t(`bon_status.${bonData.status}`)}</td>
              </tr>
              <tr>
                <th>{t("bon_type")}</th>
                <td>{bonData.type !== null ? t(`bon_types.${bonData.type}`) : "-"}</td>
              </tr>
              <tr>
                <th>{t("amount")}</th>
                <td>
                  {bonData.amount
                    ? new Intl.NumberFormat("pl", {
                        style: "currency",
                        currency: "PLN",
                      }).format(bonData.amount)
                    : "-"}
                </td>
              </tr>
              <tr>
                <th>{t("valid_until")}</th>
                <td>{bonData.valid_until ?? "-"}</td>
              </tr>
              <tr>
                <th>{t("created_by")}</th>
                <td>{bonData.created.by.fullname}</td>
              </tr>
              <tr>
                <th>{t("created_at")}</th>
                <td>{formatDate(bonData.created.at)}</td>
              </tr>
              <tr>
                <th>{t("updated_by")}</th>
                <td>{bonData.moderate.by.fullname}</td>
              </tr>
              <tr>
                <th>{t("updated_at")}</th>
                <td>{formatDate(bonData.moderate.at)}</td>
              </tr>
              {bonData.canceled && (
                <React.Fragment>
                  <tr>
                    <th>{t("canceled_by")}</th>
                    <td>{bonData.canceled.by.fullname}</td>
                  </tr>
                  <tr>
                    <th>{t("reason")}</th>
                    <td>{bonData.canceled.reason}</td>
                  </tr>
                  <tr>
                    <th>{t("canceled_at")}</th>
                    <td>{formatDate(bonData.canceled.at)}</td>
                  </tr>
                </React.Fragment>
              )}
            </thead>
          </table>
        )}
      </Modal.Body>
    </Modal>
  );
};
