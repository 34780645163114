import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BonSearchForm } from "../../bons/views/parts/searchForm";
import { SearchBonPropsInterface } from "../../bons/redux/bonTypes";
import { Col, Row } from "react-bootstrap";
import { useSearchPublicBonMutation } from "../../bons/redux/bonApi";
import { isAfter, parse } from "date-fns";
import { StatisticTypeEnum } from "../../statistics/redux/statisticTypes";
import { toast } from "react-toastify";

export const ClientDashboard = () => {
  const { t } = useTranslation();
  const [searchBon, { data, error }] = useSearchPublicBonMutation();

  const onSubmit = (data: SearchBonPropsInterface) => {
    searchBon(data);
  };

  useEffect(() => {
    let e: { data: { message: string }; status: number } = error as any;
    if (e && e.status !== 200) {
      toast.error(e.data.message || t("data_incorrect"));
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("client_bon_search")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <Row>
            <Col>
              <BonSearchForm
                onSubmit={onSubmit}
                resetForm={false}
                searchFor="client"
              />
            </Col>
          </Row>
        </div>
      </div>
      {data && (
        <React.Fragment>
          <Row className="mt-4">
            <Col>
              <h5>
                {t("bon_card_active_to")}{" "}
                {data.valid_until &&
                !isAfter(new Date(), new Date(data.valid_until)) ? (
                  <span className="text-danger ml-2 font-weight-bold">
                    {data.valid_until}
                  </span>
                ) : (
                  <span className="text-success ml-2 font-weight-bold">
                    {data.valid_until}
                  </span>
                )}
              </h5>
              <h5>
                {t("you_can_buy")}{" "}
                <span className="text-success ml-2 font-weight-bold">
                  {t(`bon_types.${data.type}`)}
                </span>
              </h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="iq-card">
                <div className="iq-card-body">
                  <table className="table table-bordered">
                    <tbody>
                      {data.transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>{transaction.type_text}</td>
                          <td
                            className={`${
                              transaction.type === StatisticTypeEnum.BUY
                                ? "bg-danger"
                                : "bg-success"
                            }`}
                          >
                            {new Intl.NumberFormat("pl", {
                              style: "currency",
                              currency: "PLN",
                            }).format(transaction.amount)}
                          </td>
                          <td>{transaction.datetime}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h5>
                    {t("bon_card_balance")}
                    <span className="font-weight-bold ml-2 text-success">
                      {new Intl.NumberFormat("pl", {
                        style: "currency",
                        currency: "PLN",
                      }).format(data.amount)}
                    </span>
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
