import { useTranslation } from "react-i18next";
import { useAppSelector, useBreadcrumbs } from "../../../app/hooks";
import {
  useGetStatisticsQuery,
  useLazyGetExportStatisticsQuery,
} from "../redux/statisticApi";
import {
  GetStatisticPropsInterface,
  GetStatisticPropsInterfaceWithPagination,
  StatisticInterface,
  StatisticTypeEnum,
} from "../redux/statisticTypes";
import React, { useEffect, useMemo, useState } from "react";
import { Loading } from "../../components/loading";
import { Button, Col, Row } from "react-bootstrap";
import { StatisticsFilters } from "./parts/filters";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "../../components/pagination";

type StatisticsProps = {
  title: string;
  statsType: StatisticTypeEnum;
};

export const Statistics = (props: StatisticsProps) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);

  const crumbs = useMemo(() => {
    const statisticBy =
      props.statsType === StatisticTypeEnum.BUY ? "charge" : "sell";

    return [
      {
        url: `/panel/statistics/by-${statisticBy}`,
        label: `menu.statistics_by_${statisticBy}`,
      },
    ];
  }, [props.statsType]);

  useBreadcrumbs(crumbs);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const [queryParams, setQueryParams] =
    useState<GetStatisticPropsInterfaceWithPagination>({
      stats_type: props.statsType,
      page: pageIndex + 1,
      per_page: pageSize,
    });

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      page: pageIndex + 1,
      per_page: pageSize,
    });
  }, [pageIndex]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      stats_type: props.statsType,
    });
  }, [props.statsType]);

  const { data: statisticsData, isLoading } =
    useGetStatisticsQuery(queryParams);

  const [downloadStatistics] = useLazyGetExportStatisticsQuery();

  const [data, setData] = useState<StatisticInterface[]>([]);

  const columnHelper = createColumnHelper<StatisticInterface>();
  const [showData, setShowData] = useState(true);

  const download = (exportFormat: "csv" | "xlsx") => {
    downloadStatistics({
      ...queryParams,
      export: exportFormat,
    });
  };

  useEffect(() => {
    const savedSalonId = localStorage.getItem("selectedSaloon");

    if (user && savedSalonId) {
      setQueryParams({
        ...queryParams,
        place: parseInt(savedSalonId),
      });
    }
  }, []);

  const onChange = (
    value: string | number,
    key: keyof GetStatisticPropsInterface
  ) => {
    setQueryParams({
      ...queryParams,
      [key]: value,
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.boncard, {
        header: t("bon_number"),
        id: "bon_number",
      }),
      columnHelper.accessor((row) => row.document, {
        header: t("document_number"),
        id: "document_number",
      }),
      columnHelper.accessor(
        (row) =>
          new Intl.NumberFormat("pl", {
            style: "currency",
            currency: "PLN",
          }).format(row.amount),
        {
          header: t("amount"),
          id: "amount",
        }
      ),
      columnHelper.accessor((row) => row.valid_until, {
        id: "valid_until",
        header: t("valid_until"),
      }),
      columnHelper.accessor((row) => t(`bon_types.${row.boncard_type}`), {
        header: t("bon_type"),
        id: "bon_type",
      }),
      columnHelper.accessor((row) => row.transaction_date, {
        header: t("transaction_date"),
        id: "transaction_date",
      }),
      columnHelper.accessor((row) => row.transaction_salon, {
        header: t("salon"),
        id: "salon",
      }),
      columnHelper.accessor((row) => row.transaction_user, {
        header: t("user"),
        id: "user",
      }),
    ],
    [columnHelper, t]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: statisticsData?.info.total_items,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    if (statisticsData && showData) {
      setData(statisticsData.items);
    }
  }, [statisticsData]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t(props.title)}</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <StatisticsFilters onChange={onChange} />
              <div className="d-flex justify-content-end">
                <div className="mr-2">
                  <Button variant="primary" onClick={() => download("csv")}>
                    {t("download_csv")}
                  </Button>
                </div>
                <div>
                  <Button variant="primary" onClick={() => download("xlsx")}>
                    {t("download_xlsx")}
                  </Button>
                </div>
              </div>
              <table className="table" style={{ marginTop: "4rem" }}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={statisticsData?.info.total_pages ?? 0}
                  table={table}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
