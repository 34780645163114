import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";

export const ProfileShow = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("profile")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          {user && (
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>{t("full_name")}</th>
                  <td>{`${user.name} ${user.surname}`}</td>
                </tr>
                <tr>
                  <th>{t("email")}</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th>{t("salon")}</th>
                  <td>{user.salon ? user.salon.name : t("no_salon")}</td>
                </tr>
                <tr>
                  <th>{t("role")}</th>
                  <td>{user.role ? t(`roles.${user.role}`) : t("no_role")}</td>
                </tr>
              </thead>
            </table>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
