import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

type PaginationProps = {
  pageCount: number;
  table: any;
};

export const Pagination = (props: PaginationProps) => {
  const { table, pageCount } = props;
  const handlePagination = (e: {
    index: number | null;
    selected: number;
    nextSelectedPage: number | undefined;
    event: object;
    isPrevious: boolean;
    isNext: boolean;
    isBreak: boolean;
    isActive: boolean;
  }) => {
    if (e.isNext) {
      table.nextPage();

      return;
    }

    if (e.isPrevious) {
      table.previousPage();

      return;
    }

    table.setPageIndex(e.nextSelectedPage ?? 0);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
      onClick={(e) => handlePagination(e)}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
      containerClassName="pagination pagination-sm"
      pageClassName="page-item cursor-pointer"
      pageLinkClassName="page-link"
      activeClassName="active"
      previousClassName={"page-item cursor-pointer"}
      previousLinkClassName="page-link"
      nextLinkClassName={"page-link"}
      nextClassName={"page-item cursor-pointer"}
      breakLinkClassName={"page-link"}
    />
  );
};
