import { StatisticTypeEnum } from "../../statistics/redux/statisticTypes";
import {
  PaginationInterface,
  PaginationPropsInterface,
  SuccessResponseInterface,
} from "./../../../app/types";

interface ModifiedByUserInterface {
  id: number;
  fullname: string;
  email: string;
}

export interface BonInterface {
  id: number;
  number: string;
  status: BonStatusEnum;
  type: BonTypeEnum;
  amount: number;
  valid_until: string;
  created: {
    at: string;
    by: ModifiedByUserInterface;
  };
  moderate: {
    at: string;
    by: ModifiedByUserInterface;
  };
  canceled: {
    at: string;
    by: ModifiedByUserInterface;
    reason: string;
  } | null;
}

export interface GetBonsSuccessResponse extends PaginationInterface {
  items: BonInterface[];
}

export interface GetBonSuccessResponse extends SuccessResponseInterface {
  data: BonInterface;
}

export enum BonStatusEnum {
  PENDING = 0,
  ACTIVE = 1,
  CANCELED = 2,
  USED = 3,
}

export enum BonTypeEnum {
  FOR_ALL = 0,
  FOR_ONLY_LENSES = 1,
  FOR_ONLY_MEDICAL_SETS = 2,
}

export interface GetBonsPropsInterface extends PaginationPropsInterface {
  query?: string;
  status?: BonStatusEnum;
}

export interface CancelBonPropsInterface {
  canceled_reason: string;
}

export interface SearchBonPropsInterface {
  number: string;
  pin?: string;
  searchForType: string;
}

export interface SearchBonSuccessResponse extends SuccessResponseInterface {
  data: BonInterface;
}

export interface ChargeBonPropsInterface {
  document: string;
  amount: number;
  type: BonTypeEnum;
  expired_months: number;
}

export interface PurchaseWithBonPropsInterface {
  document: string;
  amount: number;
}

export interface PublicBonInterface extends BonInterface {
  transactions: {
    type: StatisticTypeEnum;
    type_text: string;
    datetime: string;
    salon: {
      name: string;
    };
    amount: number;
  }[];
}

export interface SearchPublicBonSuccessResponse
  extends SuccessResponseInterface {
  data: PublicBonInterface;
}
