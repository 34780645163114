import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "./store";
import {BreadCrumbInterface} from "../features/components/breadcrumbs/breadcrumbs.types";
import {useLayoutEffect} from "react";
import {setBreadcrumbs} from "../states/breadcrumbState";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useBreadcrumbs = (crumbs: BreadCrumbInterface[]) => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(setBreadcrumbs(crumbs));
  }, [crumbs, dispatch]);
};
