import React, { useEffect } from "react";
import logo from "./../../../assets/images/logo.jpg";

export const ClientNavbar = () => {
  useEffect(() => {
    const body = document.body;
    body.classList.add("top-tab-horizontal");
  }, []);

  return (
    <React.Fragment>
      <div className="iq-top-navbar">
        <div className="top-menu">
          <div
            className="container d-flex h-100 align-items-center justify-content-center"
            style={{ minHeight: "76px" }}
          >
            <div className="iq-navbar-custom text-center">
              <div className="iq-sidebar-logo">
                <div className="top-logo">
                  <img src={logo} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
