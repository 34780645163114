import { format, parse } from "date-fns";

export const buildFilters = (filters?: object) => {
  if (!filters) {
    return;
  }

  let result = "";

  Object.entries(filters).forEach(([key, value]) => {
    result += `&filters[${key}]=${value}`;
  });

  return result;
};

export const formatDate = (date: string) =>
  format(Date.parse(date), "dd.MM.yyyy HH:mm");

export function keysOf<T extends Object>(obj: T): Array<keyof T> {
  return Array.from(Object.keys(obj)) as any;
}
