import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useAppSelector } from "../../../app/hooks";
import { getBreadcrumbs } from "../../../states/breadcrumbState";

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const crumbs = useAppSelector(getBreadcrumbs);
  const lastCrumb = useMemo(() => crumbs[crumbs.length - 1]?.label, [crumbs]);

  return (
    <React.Fragment>
      <h5 className="mb-0">{t(lastCrumb)}</h5>
      <nav aria-label="breadcrumb">
        <div className="breadcrumb">
          {crumbs.map((crumb, index) => (
            <NavLink
              end
              to={crumb.url}
              key={index}
              className={({ isActive }) =>
                classNames("breadcrumb-item", { active: isActive })
              }
            >
              <span>{t(crumb.label)}</span>
            </NavLink>
          ))}
        </div>
      </nav>
    </React.Fragment>
  );
};
