import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { LoginPropsInterface } from "../authTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { loginRequest } from "../authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { authorized, errorMessage, status } = useAppSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (authorized) {
      navigate("/panel/bons/charge");
    }
  }, []);

  const schema: yup.Schema<LoginPropsInterface> = useMemo(() => {
    return yup.object({
      email: yup.string().email().required(t("validations.field_is_required")),
      password: yup.string().required(t("validations.field_is_required")),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: LoginPropsInterface) => {
    dispatch(loginRequest(data));
  };

  useEffect(() => {
    if (authorized && status === "success") {
      toast.success(t("login_success"));

      setTimeout(() => {
        navigate("/panel/bons");
      }, 1000);
    } else if (status === "failed") {
      toast.error(errorMessage);
    }
  }, [status]);

  return (
    <React.Fragment>
      <div className="d-flex align-content-center flex-wrap justify-content-center vh-100">
        <div className="sign-in-form" style={{ width: "600px" }}>
          <h1 className="mb-0">{t("login")}</h1>
          <p>{t("login_welcome_message")}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label>{t("email")}</Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="email"
                      placeholder={t("email")}
                      {...field}
                      isInvalid={!!errors.email}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label>{t("password")}</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder={t("placeholders.enter_password")}
                      {...field}
                      isInvalid={!!errors.password}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <div className="d-inline-block w-100">
                  <button type="submit" className="btn btn-primary float-right">
                    {t("login")}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
