import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { UserRoleEnum } from "../../users/redux/userTypes";

const companyName = process.env.REACT_APP_APP_NAME;

type SidebarProps = {
  onToggleSidebar: () => void;
};

export const Sidebar = (props: SidebarProps) => {
  const { onToggleSidebar } = props;
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);

  return (
    <React.Fragment>
      <div className="iq-sidebar">
        <div className="iq-sidebar-logo d-flex justify-content-between">
          <a href="/panel/bons">
            <span>{companyName}</span>
          </a>
          <div
            className="iq-menu-bt align-self-center"
            onClick={onToggleSidebar}
          >
            <div className="wrapper-menu">
              <div className="line-menu half start"></div>
              <div className="line-menu"></div>
              <div className="line-menu half end"></div>
            </div>
          </div>
        </div>
        <div id="sidebar-scrollbar">
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="iq-menu-title">
                <i className="ri-separator"></i>
                <span>{t("bons")}</span>
              </li>
              {user && user.role === UserRoleEnum.ADMIN && (
                  <li>
                    <NavLink className={"iq-waves-effect"} to={"/panel/bons"}>
                      <i className="ri-list-check"></i>
                      <span>{t("menu.bon_list")}</span>
                    </NavLink>
                  </li>
              )}
              <li>
                <NavLink
                  className={"iq-waves-effect"}
                  to={"/panel/bons/charge"}
                >
                  <i className="ri-exchange-line"></i>
                  <span>{t("menu.selling_bons")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={"iq-waves-effect"}
                  to={"/panel/bons/purchase"}
                >
                  <i className="ri-exchange-dollar-line"></i>
                  <span>{t("menu.purchase_with_bon")}</span>
                </NavLink>
              </li>
              {user && user.role === UserRoleEnum.ADMIN && (
                <li>
                  <NavLink
                    className={"iq-waves-effect"}
                    to={"/panel/bon-imports"}
                  >
                    <i className="ri-upload-cloud-line"></i>
                    <span>{t("menu.import_bons")}</span>
                  </NavLink>
                </li>
              )}
            </ul>
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className="iq-menu-title">
                <i className="ri-separator"></i>
                <span>{t("statistics")}</span>
              </li>
              <li>
                <NavLink
                  className={"iq-waves-effect"}
                  to={"/panel/statistics/by-charge"}
                >
                  <i className="ri-bar-chart-grouped-line"></i>
                  <span>{t("menu.statistics_by_charge")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={"iq-waves-effect"}
                  to={"/panel/statistics/by-sell"}
                >
                  <i className="ri-bar-chart-line"></i>
                  <span>{t("menu.statistics_by_sell")}</span>
                </NavLink>
              </li>
            </ul>
            {user && user.role === UserRoleEnum.ADMIN && (
              <React.Fragment>
                <ul id="iq-sidebar-toggle" className="iq-menu">
                  <li className="iq-menu-title">
                    <i className="ri-separator"></i>
                    <span>{t("users")}</span>
                  </li>
                  <li>
                    <NavLink className={"iq-waves-effect"} to={"/panel/users"}>
                      <i className="ri-shield-user-fill"></i>
                      <span>{t("menu.user_list")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={"iq-waves-effect"}
                      to={"/panel/users/create"}
                    >
                      <i className="ri-user-add-line"></i>
                      <span>{t("menu.add_user")}</span>
                    </NavLink>
                  </li>
                </ul>
                <ul id="iq-sidebar-toggle" className="iq-menu">
                  <li className="iq-menu-title">
                    <i className="ri-separator"></i>
                    <span>{t("salons")}</span>
                  </li>
                  <li>
                    <NavLink className={"iq-waves-effect"} to={"/panel/salons"}>
                      <i className="ri-list-check"></i>
                      <span>{t("menu.salon_list")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={"iq-waves-effect"}
                      to={"/panel/salons/create"}
                    >
                      <i className="ri-add-line"></i>
                      <span>{t("menu.add_salon")}</span>
                    </NavLink>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </nav>
          <div className="p-3"></div>
        </div>
      </div>
    </React.Fragment>
  );
};
