import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CancelBonPropsInterface } from "../redux/bonTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useCancelBonMutation } from "../redux/bonApi";
import { CloseButtonModal } from "../../components/closeModalButton";

type BonCancelProps = {
  id: number;
  show: boolean;
  onHide: () => void;
};

export const BonCancel = (props: BonCancelProps) => {
  const { t } = useTranslation();
  const { id, show, onHide } = props;
  const [cancelBon, { isSuccess }] = useCancelBonMutation();

  const schema: yup.Schema<CancelBonPropsInterface> = useMemo(() => {
    return yup.object({
      canceled_reason: yup
        .string()
        .required(t("validations.field_is_required")),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CancelBonPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      canceled_reason: "",
    },
  });

  const onSubmit = (data: CancelBonPropsInterface) => {
    cancelBon({ id, ...data });
  };

  useEffect(() => {
    if (isSuccess) {
      onHide();
    }
  }, [isSuccess]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{t("cancel_bon")}</Modal.Title>
        <CloseButtonModal onClick={onHide} />
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{t("reason")}</Form.Label>
            <Controller
              name="canceled_reason"
              control={control}
              render={({ field }) => (
                <Form.Control
                  {...field}
                  placeholder={t("placeholders.enter_reason")}
                  as="textarea"
                  rows={3}
                  isInvalid={!!errors.canceled_reason}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.canceled_reason?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            <FontAwesomeIcon icon={faCheck} /> {t("save")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
