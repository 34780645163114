import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { logoutRequest } from "../../auth/authApi";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../breadcrumbs/breadcrumbs";
import localStorage from "redux-persist/es/storage";
import { UserRoleEnum } from "../../users/redux/userTypes";

type NavbarProps = {
  onToggleSidebar: () => void;
};

const companyName = process.env.REACT_APP_APP_NAME;

export const Navbar = (props: NavbarProps) => {
  const { onToggleSidebar } = props;
  const { t } = useTranslation();
  const { user, status } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showRemind, setShowRemind] = useState(false);

  useEffect(() => {
    localStorage.getItem("selectedSaloon").then((value) => {
      setShowRemind(value === null && user?.role !== UserRoleEnum.ADMIN);
    });
  });

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  useEffect(() => {
    if (status === "success") {
      navigate("/login");
    }
  }, [navigate, status]);

  return (
    <React.Fragment>
      <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
          <div className="iq-sidebar-logo">
            <div className="top-logo">
              <a href="/panel" className="logo">
                <span>{companyName}</span>
              </a>
            </div>
          </div>
          <div className="navbar-breadcrumb">{<Breadcrumbs />}</div>
          {showRemind && (
            <div className={"setting-remind"}>
              <span>
                Obecne stanowisko nie jest przypisane do żadnego salonu salonu.
              </span>
              <a className={"px-2"} href={"/panel/hide-configuration"}>
                Ustaw to kilkając tutaj
              </a>
            </div>
          )}
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="iq-menu-bt" onClick={onToggleSidebar}>
              <div className="wrapper-menu">
                <div className="line-menu half start"></div>
                <div className="line-menu"></div>
                <div className="line-menu half end"></div>
              </div>
            </div>
            <div id="navbar-list" className="d-flex flex-row-reverse">
              <ul className="navbar-list">
                <Dropdown className="h-full" as={"li"}>
                  <Dropdown.Toggle
                    as={"a"}
                    className="search-toggle text-center iq-waves-effect bg-primary text-white"
                    style={{ width: "60px" }}
                  >
                    <i
                      className="ri-user-fill"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="iq-user-dropdown p-0">
                    <Dropdown.Header className="iq-card shadow-none m-0 p-0">
                      <div className="iq-card-body p-0">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white line-height">
                            {t("user_menu.hello")} {user?.name}
                          </h5>
                        </div>
                      </div>
                    </Dropdown.Header>
                    <Dropdown.Item
                      as={"a"}
                      href="/panel/profile"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center d-flex gap-4">
                        <div className="rounded iq-card-icon iq-bg-primary">
                          <i className="ri-file-user-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">{t("user_menu.my_profile")}</h6>
                          <p className="mb-0 font-size-12">
                            {t("user_menu.view_personal_details")}
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={"a"}
                      href="/panel/profile/edit"
                      className="iq-sub-card iq-bg-primary-hover"
                    >
                      <div className="media align-items-center d-flex gap-4">
                        <div className="rounded iq-card-icon iq-bg-success">
                          <i className="ri-profile-line"></i>
                        </div>
                        <div className="media-body ml-3">
                          <h6 className="mb-0 ">
                            {t("user_menu.edit_profile")}
                          </h6>
                          <p className="mb-0 font-size-12">
                            {t("user_menu.modify_your_personal_details")}
                          </p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <div className="d-inline-block w-100 text-center p-3">
                      <button
                        className="iq-bg-danger iq-sign-btn"
                        type="button"
                        onClick={onLogout}
                      >
                        {t("user_menu.logout")}
                        <i className="ri-login-box-line ml-"></i>
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};
