import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useGetUserQuery } from "../redux/userApi";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatDate } from "../../../app/helpers";
import { Loading } from "../../components/loading";

export const UserDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data } = useGetUserQuery(id!);

  if (!data) return <Loading />;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  {t("user_details")} - {`${data.name} ${data.surname}`}
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>{t("full_name")}</th>
                    <td>{`${data.name} ${data.surname}`}</td>
                  </tr>
                  <tr>
                    <th>{t("email")}</th>
                    <td>{data.email}</td>
                  </tr>
                  <tr>
                    <th>{t("salon")}</th>
                    <td>{data.salon ? data.salon.name : t("no_salon")}</td>
                  </tr>
                  <tr>
                    <th>{t("role")}</th>
                    <td>
                      {data.role ? t(`roles.${data.role}`) : t("no_role")}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
