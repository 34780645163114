type CloseButtonModalProps = {
  onClick: () => void;
};

export const CloseButtonModal = (props: CloseButtonModalProps) => {
  return (
    <button onClick={props.onClick} className="btn-close btn">
      <i className="ri-close-line" style={{ fontSize: "1.5em" }}></i>
    </button>
  );
};
