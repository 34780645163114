import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {logoutUser} from "../features/auth/authSlice";
import {persistor} from "./store";

export const rtkQueryOnForbiddenLogout: Middleware = (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
        api.dispatch(logoutUser())
        persistor.purge()
    }

    return next(action);
}