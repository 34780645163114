import {
  PaginationInterface,
  PaginationPropsInterface,
} from "../../../app/types";
import { BonTypeEnum } from "./../../bons/redux/bonTypes";

export interface StatisticInterface {
  amount: number;
  boncard: string;
  boncard_type: BonTypeEnum;
  document: string;
  transaction_date: string;
  transaction_salon: string;
  transaction_user: string;
  valid_until?: string;
}

export enum StatisticTypeEnum {
  SELL = 0,
  BUY = 1,
}

export interface GetStatisticSuccessResponse extends PaginationInterface {
  items: StatisticInterface[];
}

export enum StatisticExportTypeEnum {
  XLSX = "xlsx",
  CSV = "csv",
}

export interface GetStatisticPropsInterface {
  place?: number;
  number?: string;
  stats_type: StatisticTypeEnum;
  issue_amount_from?: number;
  issue_amount_to?: number;
  issue_date_from?: string;
  issue_date_to?: string;
  issue_salon_id?: number;
  export?: "xlsx" | "csv";
}

export interface GetStatisticPropsInterfaceWithPagination
  extends PaginationPropsInterface,
    GetStatisticPropsInterface {}
