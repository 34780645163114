import { useTranslation } from "react-i18next";
import {
  usePurchaseWithBonMutation,
  useSearchBonMutation,
} from "../redux/bonApi";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BonStatusEnum,
  PurchaseWithBonPropsInterface,
  SearchBonPropsInterface,
} from "../redux/bonTypes";
import { BonSearchForm } from "./parts/searchForm";
import { PurchaseBonDetails } from "./parts/purchaseDetails";
import { PurchaseWithBonForm } from "./parts/purchaseForm";
import { toast } from "react-toastify";
import { useAppSelector, useBreadcrumbs } from "../../../app/hooks";
import { UserRoleEnum } from "../../users/redux/userTypes";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";

export const PurchaseWithBon = () => {
  const { t } = useTranslation();
  const [searchBon, { data: searchBonData, error }] = useSearchBonMutation();
  const [resetSearchForm, setResetSearchForm] = useState(false);
  const [showBonDetails, setShowBonDetails] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [purchaseWithBon, { isSuccess }] = usePurchaseWithBonMutation();
  const { user } = useAppSelector((state) => state.auth);

  useBreadcrumbs(breadcrumbs);

  const onSearchSubmit = (data: SearchBonPropsInterface) => {
    setResetSearchForm(false);
    searchBon(data);
  };

  useEffect(() => {
    if (error) {
      toast.error(t("data_incorect"));
    }
  }, [error]);

  useEffect(() => {
    if (searchBonData) {
      switch (searchBonData.status) {
        case BonStatusEnum.PENDING:
          toast.warning(t("bon_inactive"));
          break;
        case BonStatusEnum.USED:
          toast.warning(t("bon_used"));
          break;
        case BonStatusEnum.CANCELED:
          toast.warning(t("bon_canceled"));
          break;
        default:
          setShowBonDetails(true);
      }
    }
  }, [searchBonData]);

  const onHideDetails = (isValid: boolean) => {
    setShowBonDetails(false);
    setIsValid(isValid);
  };

  const onPuchaseSubmit = (data: PurchaseWithBonPropsInterface) => {
    const selectedSaloon = localStorage.getItem("selectedSaloon");

    if (user && user.role === UserRoleEnum.EMPLOYER && !selectedSaloon) {
      toast.warning(t("salon_has_not_been_selected"));

      return;
    }

    if (searchBonData) {
      purchaseWithBon({
        boncard_id: searchBonData.id,
        place_of_sale: selectedSaloon,
        ...data,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("purchase_with_bon_success"));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("purchase_with_bon")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <Row>
            <Col>
              <BonSearchForm
                resetForm={resetSearchForm}
                onSubmit={onSearchSubmit}
                searchFor="purchase"
              />
            </Col>
          </Row>
          {searchBonData && (
            <PurchaseBonDetails
              show={showBonDetails}
              onHide={onHideDetails}
              bon={searchBonData}
            />
          )}
        </div>
      </div>
      {isValid && (
        <div className="iq-card">
          <div className="iq-card-header d-flex justify-content-between">
            <div className="iq-header-title">
              <h4 className="card-title">{t("form")}</h4>
            </div>
          </div>
          <div className="iq-card-body">
            <PurchaseWithBonForm
              maxAmount={searchBonData?.amount!}
              onSubmit={onPuchaseSubmit}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/bons",
    label: "menu.bon_list",
  },
  {
    url: "/panel/bons/purchase",
    label: "menu.purchase_with_bon",
  },
];
