import { useTranslation } from "react-i18next";
import { useGetSalonsQuery } from "../redux/salonApi";
import React, { useEffect, useMemo, useState } from "react";
import { SalonInterface } from "../redux/salonTypes";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ActionColumn } from "../../components/actionDropdown";
import { Pagination } from "../../components/pagination";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";
import { useBreadcrumbs } from "../../../app/hooks";

export const SalonList = () => {
  const { t } = useTranslation();

  useBreadcrumbs(breadcrumbs);

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const { data: getSalonData } = useGetSalonsQuery({
    page: pageIndex + 1,
    per_page: pageSize,
  });

  const [data, setData] = useState<SalonInterface[]>([]);

  useEffect(() => {
    if (getSalonData) {
      setData(getSalonData.items);
    }
  }, [getSalonData]);

  const columnHelper = createColumnHelper<SalonInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        header: t("salon_name"),
      }),
      columnHelper.accessor((row) => row.address, {
        id: "address",
        header: t("address"),
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <ActionColumn editLink={`/panel/salons/${row.original.id}/edit`} />
        ),
      }),
    ],
    [columnHelper, t]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: getSalonData?.info.total_items,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("salons")}</h4>
              </div>
              <div>
                <Button
                  as={"a"}
                  variant="success"
                  href={"/panel/salons/create"}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  {t("add_salon")}
                </Button>
              </div>
            </div>
            <div className="iq-card-body">
              <table className="table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={getSalonData?.info.total_pages ?? 0}
                  table={table}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/salons",
    label: "menu.salon_list",
  },
];
