import { useTranslation } from "react-i18next";
import { useStoreSalonMutation } from "../redux/salonApi";
import { SaveSalonPropsInterface } from "../redux/salonTypes";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { SalonForm } from "./parts/form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";
import { useBreadcrumbs } from "../../../app/hooks";

export const SalonCreate = () => {
  const { t } = useTranslation();
  const [storeSalon, { isSuccess, isError, error }] = useStoreSalonMutation();
  const navigate = useNavigate();

  useBreadcrumbs(breadcrumbs);

  const onSubmit = (data: SaveSalonPropsInterface) => {
    storeSalon(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("salon_created_successfully"));
      navigate("/panel/salons");
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("creating_new_salon")}</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <SalonForm isError={isError} error={error} onSubmit={onSubmit} />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/salons",
    label: "menu.salon_list",
  },
  {
    url: "/panel/salons/create",
    label: "menu.salons_create",
  },
];
