import { bonCardApi } from "../../../app/bonCardApi";
import {
  BonInterface,
  CancelBonPropsInterface,
  ChargeBonPropsInterface,
  GetBonSuccessResponse,
  GetBonsPropsInterface,
  GetBonsSuccessResponse,
  PublicBonInterface,
  PurchaseWithBonPropsInterface,
  SearchBonPropsInterface,
  SearchBonSuccessResponse,
  SearchPublicBonSuccessResponse,
} from "./bonTypes";

export const bonApi = bonCardApi.injectEndpoints({
  endpoints: (builder) => ({
    getBons: builder.query<GetBonsSuccessResponse, GetBonsPropsInterface>({
      query: (params) => ({
        url: "/api/boncards",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: "Bons", id } as const)),
              { type: "Bons", id: "LIST" },
            ]
          : [{ type: "Bons", id: "LIST" }],
    }),
    getBon: builder.query<BonInterface, number>({
      query: (id) => ({
        url: `/api/boncards/${id}`,
      }),
      providesTags: (result, error, id) => [{ type: "Bons", id: id }],
      transformResponse: (response: GetBonSuccessResponse) => response.data,
    }),
    cancelBon: builder.mutation<void, CancelBonPropsInterface & { id: number }>(
      {
        query: ({ id, ...props }) => ({
          url: `/api/boncards/${id}/action/cancel`,
          method: "POST",
          body: props,
        }),
        invalidatesTags: [{ type: "Bons", id: "LIST" }],
      }
    ),
    searchBon: builder.mutation<BonInterface, SearchBonPropsInterface>({
      query: (params) => ({
        url: "/api/boncards/search",
        method: "POST",
        body: params,
      }),
      transformResponse: (response: SearchBonSuccessResponse) => response.data,
    }),
    chargeBon: builder.mutation<
      void,
      ChargeBonPropsInterface & {
        boncard_id: number;
        place_of_sale?: number | string | null;
      }
    >({
      query: (props) => ({
        url: "/api/boncards/issue",
        method: "POST",
        body: props,
      }),
    }),
    purchaseWithBon: builder.mutation<
      void,
      PurchaseWithBonPropsInterface & {
        boncard_id: number;
        place_of_sale?: number | string | null;
      }
    >({
      query: (props) => ({
        url: `/api/boncards/purchase`,
        method: "POST",
        body: props,
      }),
    }),
    searchPublicBon: builder.mutation<
      PublicBonInterface,
      SearchBonPropsInterface
    >({
      query: (params) => ({
        url: "/api/check-boncard",
        method: "POST",
        body: params,
      }),
      transformResponse: (response: SearchPublicBonSuccessResponse) =>
        response.data,
    }),
  }),
});

export const {
  useGetBonsQuery,
  useLazyGetBonsQuery,
  useGetBonQuery,
  useCancelBonMutation,
  useSearchBonMutation,
  useChargeBonMutation,
  usePurchaseWithBonMutation,
  useSearchPublicBonMutation,
} = bonApi;
