import { useTranslation } from "react-i18next";
import { SearchBonPropsInterface } from "../../redux/bonTypes";
import React, { useEffect, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";

type BonSearchProps = {
  onSubmit: (data: SearchBonPropsInterface) => void;
  resetForm: boolean;
  searchFor: string;
};

export const BonSearchForm = (props: BonSearchProps) => {
  const { t } = useTranslation();
  const { onSubmit, resetForm, searchFor } = props;

  const schema: yup.Schema = useMemo(() => {
    return yup.object({
      searchForType: yup.string().required(),
      number: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(
          20,
          `${t("validations.field_min_length", {
            count: 20,
          })} ${t("characters_interval", {
            postProcess: "interval",
            count: 20,
          })}`
        )
        .max(
          20,
          `${t("validations.field_max_length", {
            count: 20,
          })} ${t("characters_interval", {
            postProcess: "interval",
            count: 20,
          })}`
        ),
      pin: yup
        .string()
        .required(t("validations.field_is_required"))
        .min(
          3,
          `${t("validations.field_min_length", {
            count: 3,
          })} ${t("characters_interval", {
            postProcess: "interval",
            count: 3,
          })}`
        )
        .max(
          3,
          `${t("validations.field_max_length", {
            count: 3,
          })} ${t("characters_interval", {
            postProcess: "interval",
            count: 20,
          })}`
        )
        .when("searchForType", ([searchForType], schema) => {
          return searchForType !== "charge"
            ? schema
            : schema.notRequired().min(0);
        }),
    });
  }, [searchFor]);

  const showPinField: boolean = useMemo(() => {
    return searchFor !== "charge";
  }, [searchFor]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SearchBonPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      pin: "",
      number: "",
      searchForType: searchFor,
    },
  });

  useEffect(() => {
    if (resetForm) {
      reset();
    }
  }, [resetForm]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} sm={12} lg={4} className="mb-2">
            <Form.Label>{t("bon_number")}</Form.Label>
            <Controller
              name="number"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.number}
                  {...field}
                  placeholder={t("placeholders.enter_number")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.number?.message}
            </Form.Control.Feedback>
          </Form.Group>
          {showPinField && (
            <Form.Group as={Col} sm={12} lg={4} className="mb-2">
              <Form.Label>{t("pin")}</Form.Label>
              <Controller
                name="pin"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder={t("placeholders.enter_pin")}
                    isInvalid={!!errors.pin}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pin?.message}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group as={Col} className="col-sm">
            <Button
              style={{ marginTop: "2.2rem" }}
              variant="primary"
              type="submit"
              size="lg"
            >
              {t("search")}
            </Button>
          </Form.Group>
        </Row>
      </form>
    </React.Fragment>
  );
};
