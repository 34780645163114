import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as yup from "yup";
import { UpdateAccountPropsInterface } from "../authTypes";
import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { updateAuthAccountRequest } from "../authApi";
import { toast } from "react-toastify";

export const ProfileEdit = () => {
  const { t } = useTranslation();
  const { user, status, errorMessage } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const schema: yup.Schema<UpdateAccountPropsInterface> = useMemo(() => {
    return yup.object({
      password: yup.string().required(t("validations.field_is_required")),
      passwordConfirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf(
          [yup.ref("password"), ""],
          t("validations.passwords_must_match")
        ),
      currentPassword: yup
        .string()
        .required(t("validations.field_is_required")),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateAccountPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
      currentPassword: "",
    },
  });

  const onSubmit = (data: UpdateAccountPropsInterface) => {
    dispatch(updateAuthAccountRequest(data));
  };

  useEffect(() => {
    if (status === "failed") {
      toast.error(errorMessage);
    } else if (status === "success") {
      toast.success(t("profile_updated"));
    }
  }, [status]);

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("user_menu.edit_profile")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group as={Col} md="2">
                <Form.Label>{t("password")}</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      isInvalid={!!errors.password}
                      {...field}
                      placeholder={t("placeholders.enter_password")}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>{t("password_confirmation")}</Form.Label>
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      isInvalid={!!errors.passwordConfirmation}
                      {...field}
                      placeholder={t(
                        "placeholders.enter_password_confirmation"
                      )}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirmation?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2">
                <Form.Label>{t("current_password")}</Form.Label>
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      isInvalid={!!errors.currentPassword}
                      {...field}
                      placeholder={t("placeholders.enter_current_password")}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.currentPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mt-4">
              <Col>
                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faCheck} /> {t("save")}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
