import { PaginationPropsInterface } from "../../../app/types";
import { bonCardApi } from "./../../../app/bonCardApi";
import { GetBonImportsSuccessResponse } from "./bonImportTypes";
export const bonImportApi = bonCardApi.injectEndpoints({
  endpoints: (build) => ({
    getBonImports: build.query<
      GetBonImportsSuccessResponse,
      PaginationPropsInterface
    >({
      query: (params) => ({
        url: "/api/boncards-imports",
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: "Bons", id } as const)),
              { type: "BonImports", id: "LIST" },
            ]
          : [{ type: "BonImports", id: "LIST" }],
    }),
    importBons: build.mutation<void, FormData>({
      query: (formData) => ({
        url: "/api/boncards-imports",
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "BonImports", id: "LIST" }],
    }),
  }),
});

export const { useGetBonImportsQuery, useImportBonsMutation } = bonImportApi;
