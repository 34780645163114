import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";

const url = process.env.REACT_APP_BACKEND_URL;

export const bonCardApi = createApi({
  reducerPath: "bonCardApi",
  tagTypes: ["Users", "Salons", "Bons", "BonImports", "Statistics"],
  baseQuery: fetchBaseQuery({
    baseUrl: url,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.bearerToken;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      if (headers.get("Content-Type") === "multipart/form-data") {
        headers.delete("Content-Type");
      } else {
        headers.set("Content-Type", "application/json");
      }

      headers.set("Accept", "application/json");

      return headers;
    },
  }),
  endpoints: (builder) => ({}),
});
