import {
  createDraftSafeSelector,
  createSlice,
  isPending,
} from "@reduxjs/toolkit";
import { AuthStateInterface, LogInSuccessResponseInterface } from "./authTypes";
import {
  activateAccountRequest,
  loginRequest,
  logoutRequest,
  updateAuthAccountRequest,
} from "./authApi";
import { RootState } from "../../app/store";

const initialState: AuthStateInterface = {
  bearerToken: null,
  authorized: false,
  loading: false,
  errorMessage: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAuthAccountRequest.fulfilled, (state, action) => {
      if (action.payload.data === 422) {
        state.errorMessage = action.payload.message;
        state.status = "failed";
      } else {
        state.status = "success";
      }
    });

    builder.addCase(loginRequest.fulfilled, (state, action) => {
      if (action.payload.code && action.payload.code === 200) {
        const payload = action.payload as LogInSuccessResponseInterface;

        state.bearerToken = payload.data.access_token;
        state.user = payload.data.user_data;
        state.authorized = true;
      } else {
        state.errorMessage = action.payload.message;
        state.status = "failed";
      }
    });

    builder.addCase(activateAccountRequest.fulfilled, (state, action) => {
      if (action.payload.code && action.payload.code === 200) {
        state.status = "success";
      } else {
        state.status = "failed";
      }
    });

    builder.addCase(logoutRequest.fulfilled, (state, action) => {
      if (action.payload.code && action.payload.code === 200) {
        state.bearerToken = null;
        state.user = undefined;
        state.authorized = false;
        state.status = "success";
      } else {
        state.status = "failed";
      }
    });

    builder.addMatcher(isPending, (state) => {
      state.status = undefined;
      state.errorMessage = null;
    });
  },
});

export const { logoutUser } = authSlice.actions;

export const getIsAuthorized = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.authorized
);

export const getRole = createDraftSafeSelector(
  (state: RootState) => state.auth,
  (auth) => auth.user?.role
);

export default authSlice.reducer;
