import { useTranslation } from "react-i18next";
import { PurchaseWithBonPropsInterface } from "../../redux/bonTypes";
import React, { useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";

type BonPurchaseProps = {
  onSubmit: (data: PurchaseWithBonPropsInterface) => void;
  maxAmount: number;
};

export const PurchaseWithBonForm = (props: BonPurchaseProps) => {
  const { t } = useTranslation();
  const { onSubmit } = props;

  const schema: yup.Schema<PurchaseWithBonPropsInterface> = useMemo(() => {
    return yup.object({
      document: yup.string().required(t("validations.field_is_required")),
      amount: yup
        .number()
        .typeError(t("validations.field_must_be_a_number"))
        .required(t("validations.field_is_required"))
        .min(1, t("validations.field_must_be_greater_than", { min: 1 }))
        .max(
          props.maxAmount,
          t("validations.field_must_be_less_than", { max: props.maxAmount })
        ),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PurchaseWithBonPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      document: "",
      amount: 0,
    },
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("document_number")}</Form.Label>
            <Controller
              name="document"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.document}
                  {...field}
                  placeholder={t("placeholders.enter_document_number")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.document?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="1">
            <Form.Label>{t("purchase_amount")}</Form.Label>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  isInvalid={!!errors.amount}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.amount?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">
              {t("save")}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
