import { useTranslation } from "react-i18next";
import { BonImportInterface } from "../redux/bonImportTypes";
import { Col, Modal, Row } from "react-bootstrap";
import React from "react";

type BonImportDetailsProps = {
  bonImport: BonImportInterface;
  show: boolean;
  onHide: () => void;
};

export const BonImportDetails = (props: BonImportDetailsProps) => {
  const { bonImport, show, onHide } = props;
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{t("bon_import_details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>#</th>
              <td>{bonImport.id}</td>
            </tr>
            <tr>
              <th>{t("status")}</th>
              <td>{t(`bon_imports_status.${bonImport.status}`)}</td>
            </tr>
            {bonImport.stats && (
              <React.Fragment>
                <tr>
                  <th>{t("bon_imports_stats_ok")}</th>
                  <td>
                    <span className="text-success">{bonImport.stats.ok}</span>
                  </td>
                </tr>
                <tr>
                  <th>{t("bon_imports_stats_error")}</th>
                  <td>
                    <span className="text-danger">{bonImport.stats.error}</span>
                  </td>
                </tr>
                <tr>
                  <th>{t("bon_imports_stats_warning")}</th>
                  <td>
                    <span className="text-warning">
                      {bonImport.stats.duplicates}
                    </span>
                  </td>
                </tr>
              </React.Fragment>
            )}
          </thead>
        </table>
        <Row className="text-center">
          <Col>
            <div className="text-muted">{t("imported_bon_numbers")}</div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {
              <ul>
                {bonImport.bons.map((bon) => (
                  <li>{bon.number}</li>
                ))}
              </ul>
            }
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
