import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useImportBonsMutation } from "../redux/bonImportApi";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

type BonImportProps = {
  show: boolean;
  onHide: () => void;
};

export const BonImport = (props: BonImportProps) => {
  const { show, onHide } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [importBons, { isSuccess, error }] = useImportBonsMutation();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("file", file, file.name);

      importBons(formData);
    } else {
      const input = document.querySelector(
        "input[type=file]"
      ) as HTMLInputElement;

      if (input) {
        input.classList.add("is-invalid");
      }
    }
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);

      const fileName = e.target.files[0].name;
      const label = document.querySelector(".custom-file-label");

      if (label) {
        label.innerHTML = fileName;
      }

      const input = document.querySelector(
        "input[type=file]"
      ) as HTMLInputElement;

      if (input) {
        input.classList.remove("is-invalid");
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onHide();
      toast.success(t("bon_import_in_process"));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(t("something_went_wrong"));
    }
  }, [error]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>{t("bon_import")}</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <Form.Group>
            <div className="custom-file">
              <Form.Control
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                className="custom-file-input"
                onChange={onFileChange}
              />
              <Form.Label className="custom-file-label">
                {t("choose_file")}
              </Form.Label>
              <Form.Control.Feedback id="input-file-feedback" type="invalid">
                {t("validations.field_is_required")}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit">
            {t("import")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
