import { useTranslation } from "react-i18next";
import { useChargeBonMutation, useSearchBonMutation } from "../redux/bonApi";
import React, { useEffect, useState } from "react";
import {
  BonStatusEnum,
  ChargeBonPropsInterface,
  SearchBonPropsInterface,
} from "../redux/bonTypes";
import { Col, Row } from "react-bootstrap";
import { BonSearchForm } from "./parts/searchForm";
import { BonChargeForm } from "./parts/chargeForm";
import { toast } from "react-toastify";
import { useAppSelector, useBreadcrumbs } from "../../../app/hooks";
import { UserRoleEnum } from "../../users/redux/userTypes";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";

export const BonCharge = () => {
  const { t } = useTranslation();
  const [searchBon, { data: searchBonData, error }] = useSearchBonMutation();
  const [showChargeForm, setShowChargeForm] = useState(false);
  const [resetChargeForm, setResetChargeForm] = useState(false);
  const [chargeBon, { isSuccess }] = useChargeBonMutation();
  const [resetSearchForm, setResetSearchForm] = useState(false);
  const { user } = useAppSelector((state) => state.auth);

  useBreadcrumbs(breadcrumbs);

  const onSearchSubmit = (data: SearchBonPropsInterface) => {
    setShowChargeForm(false);
    setResetChargeForm(true);
    setResetSearchForm(false);
    searchBon(data);
  };

  useEffect(() => {
    if (error) {
      toast.error(t("data_incorect"));
    }
  }, [error]);

  useEffect(() => {
    if (searchBonData) {
      if (
        searchBonData.status === BonStatusEnum.USED ||
        searchBonData.status === BonStatusEnum.PENDING
      ) {
        setShowChargeForm(true);
        setResetChargeForm(false);
      } else {
        toast.error(t("bon_not_available"), {
          position: "top-center",
        });
      }
    }
  }, [searchBonData]);

  const onChargeSubmit = (data: ChargeBonPropsInterface) => {
    const selectedSaloon = localStorage.getItem("selectedSaloon");

    if (user && user.role === UserRoleEnum.EMPLOYER && !selectedSaloon) {
      toast.warning(t("salon_has_not_been_selected"));

      return;
    }

    if (searchBonData) {
      chargeBon({
        boncard_id: searchBonData.id,
        place_of_sale: selectedSaloon,
        ...data,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("bon_charged"));
      setShowChargeForm(false);
      setResetChargeForm(true);
      setResetSearchForm(true);
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">{t("selling_bons")}</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <Row>
            <Col>
              <BonSearchForm
                resetForm={resetSearchForm}
                onSubmit={onSearchSubmit}
                searchFor="charge"
              />
            </Col>
          </Row>
          <hr style={{ margin: "1rem 0" }} />
          {showChargeForm && (
            <BonChargeForm
              resetForm={resetChargeForm}
              onSubmit={onChargeSubmit}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/bons",
    label: "menu.bon_list",
  },
  {
    url: "/panel/bons/charge",
    label: "menu.selling_bons",
  },
];
