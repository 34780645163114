import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { BonInterface, BonStatusEnum } from "../../redux/bonTypes";
import { BonDetails } from "../show";
import { BonCancel } from "../cancel";

type ActionColumnProps = {
  bon: BonInterface;
};

export const ActionColumn = (props: ActionColumnProps) => {
  const { bon } = props;
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [showCancelForm, setShowCancelForm] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-action"
          style={{ background: "none", border: "0", color: "#a6b0cf" }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            role="button"
            onClick={() => setShowDetails(!showDetails)}
          >
            {t("show_details")}
          </Dropdown.Item>
          {bon.status !== BonStatusEnum.CANCELED && (
            <Dropdown.Item
              role="button"
              onClick={() => setShowCancelForm(!showCancelForm)}
            >
              {t("cancel_bon")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {showDetails && (
        <BonDetails
          show={showDetails}
          onHide={() => setShowDetails(!setShowDetails)}
          id={bon.id}
        />
      )}

      <BonCancel
        show={showCancelForm}
        onHide={() => setShowCancelForm(false)}
        id={bon.id}
      />
    </React.Fragment>
  );
};
