import React, { useEffect, useMemo, useState } from "react";
import { useDeleteUserMutation, useGetUsersQuery } from "../redux/userApi";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserInterface, UserStatusEnum } from "../redux/userTypes";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ActionColumn } from "../../components/actionDropdown";
import { Pagination } from "../../components/pagination";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

export const UserList = () => {
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const [deleteUser, { isSuccess }] = useDeleteUserMutation();
  const { t } = useTranslation();

  const onDeleteUser = (user: UserInterface) => {
    if (user.status !== UserStatusEnum.DISABLED) {
      deleteUser(user.id);

      return;
    }

    toast.warning(t("user_already_deleted"));
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("user_successfully_deleted"));
    }
  }, [isSuccess]);

  const { data: getUsersData } = useGetUsersQuery({
    page: pageIndex + 1,
    per_page: pageSize,
  });

  const [data, setData] = useState<UserInterface[]>([]);

  useEffect(() => {
    if (getUsersData) {
      setData(getUsersData.items);
    }
  }, [getUsersData]);

  const columnHelper = createColumnHelper<UserInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => `${row.name} ${row.surname}`, {
        id: "fullname",
        header: t("full_name"),
      }),
      columnHelper.accessor((row) => row.email, {
        id: "email",
        header: t("email"),
      }),
      columnHelper.accessor((row) => t(`user_status.${row.status}`), {
        id: "status",
        header: t("status"),
      }),
      columnHelper.accessor(
        (row) => (row.salon ? row.salon.name : t("no_salon")),
        {
          id: "salon",
          header: t("salon"),
        }
      ),
      columnHelper.accessor(
        (row) => (row.role !== null ? t(`roles.${row.role}`) : t("no_role")),
        {
          id: "role",
          header: t("role"),
        }
      ),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <ActionColumn
            showDetailsLink={`/panel/users/${row.original.id}`}
            editLink={`/panel/users/${row.original.id}/edit`}
            onClick={() => onDeleteUser(row.original)}
          />
        ),
      }),
    ],
    [columnHelper, t]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: getUsersData?.info.total_items,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("users")}</h4>
              </div>
              <div>
                <Button as={"a"} variant="success" href={"/panel/users/create"}>
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />{" "}
                  {t("add_user")}
                </Button>
              </div>
            </div>
            <div className="iq-card-body">
              <table className="table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={getUsersData?.info.total_pages ?? 0}
                  table={table}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
