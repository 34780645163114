import { PaginationPropsInterface } from "./../../../app/types";
import {
  GetPublicSalonsSuccessResponse,
  GetSalonSuccessResponse,
  GetSalonsSuccessResponse,
  SalonInterface,
  SaveSalonPropsInterface,
} from "./salonTypes";
import { bonCardApi } from "../../../app/bonCardApi";

export const salonApi = bonCardApi.injectEndpoints({
  endpoints: (builder) => ({
    getPublicSalons: builder.query<SalonInterface[], void>({
      query: () => ({
        url: "/api/our-salons",
      }),
      transformResponse: (response: GetPublicSalonsSuccessResponse) =>
        response.items,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Salons", id } as const)),
              { type: "Salons", id: "PUBLIC_LIST" },
            ]
          : [{ type: "Salons", id: "PUBLIC_LIST" }],
    }),
    getSalons: builder.query<
      GetSalonsSuccessResponse,
      PaginationPropsInterface
    >({
      query: (props) => ({
        url: "/api/salons",
        params: props,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(
                ({ id }) => ({ type: "Salons", id } as const)
              ),
              { type: "Salons", id: "LIST" },
            ]
          : [{ type: "Salons", id: "LIST" }],
    }),
    storeSalon: builder.mutation<SalonInterface, SaveSalonPropsInterface>({
      query: (props) => ({
        url: "/api/salons",
        method: "POST",
        body: props,
      }),
      invalidatesTags: [
        { type: "Salons", id: "LIST" },
        { type: "Salons", id: "PUBLIC_LIST" },
      ],
    }),
    updateSalon: builder.mutation<
      SalonInterface,
      SaveSalonPropsInterface & { id: number | string }
    >({
      query: ({ id, ...props }) => ({
        url: `/api/salons/${id}`,
        method: "PUT",
        body: props,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Salons", id }],
    }),
    getSalon: builder.query<SalonInterface, number | string>({
      query: (id) => ({
        url: `/api/salons/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Salons", id: id }],
      transformResponse: (response: GetSalonSuccessResponse) => response.data,
    }),
  }),
});

export const {
  useGetPublicSalonsQuery,
  useGetSalonsQuery,
  useStoreSalonMutation,
  useUpdateSalonMutation,
  useGetSalonQuery,
} = salonApi;
