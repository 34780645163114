import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GetStatisticPropsInterface } from "../../redux/statisticTypes";
import { useAppSelector } from "../../../../app/hooks";
import { UserRoleEnum } from "../../../users/redux/userTypes";
import { useGetPublicSalonsQuery } from "../../../salon/redux/salonApi";

type StatisticsFiltersProps = {
  onChange: (
    value: string | number,
    key: keyof GetStatisticPropsInterface
  ) => void;
};

export const StatisticsFilters = (props: StatisticsFiltersProps) => {
  const { onChange } = props;
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { data: getSalonData = [] } = useGetPublicSalonsQuery();

  return (
    <React.Fragment>
      <Row className="mb-4" style={{ gap: "10px" }}>
        <Form.Group as={Col} md={4} lg={3}>
          <Form.Label>{t("bon_number")}</Form.Label>
          <Form.Control
            placeholder={t("placeholders.enter_number")}
            onChange={(e) => onChange(e.target.value, "number")}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} lg={2}>
          <Form.Label>{t("amount_from")}</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            placeholder="0.00"
            onChange={(e) => onChange(e.target.value, "issue_amount_from")}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} lg={2}>
          <Form.Label>{t("amount_to")}</Form.Label>
          <Form.Control
            type="number"
            step="0.01"
            placeholder="0.00"
            onChange={(e) => onChange(e.target.value, "issue_amount_to")}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} lg={2}>
          <Form.Label>{t("from_date")}</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => onChange(e.target.value, "issue_date_from")}
          />
        </Form.Group>
        <Form.Group as={Col} md={4} lg={2}>
          <Form.Label>{t("to_date")}</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => onChange(e.target.value, "issue_date_to")}
          />
        </Form.Group>
        {user?.role === UserRoleEnum.ADMIN && (
          <Form.Group as={Col} md={5} lg={3}>
            <Form.Label>{t("salon")}</Form.Label>
            <Form.Select
              className="form-control"
              onChange={(e) => onChange(e.target.value, "place")}
            >
              <option value="">{t("chose_salon")}</option>
              {getSalonData.map((salon) => (
                <option key={salon.id} value={salon.id}>
                  {salon.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </Row>
    </React.Fragment>
  );
};
