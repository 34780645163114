import { useTranslation } from "react-i18next";
import { BonInterface, BonStatusEnum } from "../../redux/bonTypes";
import { Button, Modal } from "react-bootstrap";
import { isAfter } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { CloseButtonModal } from "../../../components/closeModalButton";

type PurchaseBonDetailsProps = {
  bon: BonInterface;
  show: boolean;
  onHide: (isValid: boolean) => void;
};

export const PurchaseBonDetails = (props: PurchaseBonDetailsProps) => {
  const { bon, show, onHide } = props;
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={() => onHide(false)}>
      <Modal.Header>
        <Modal.Title>{t("bon_details")}</Modal.Title>
        <CloseButtonModal onClick={() => onHide(false)} />
      </Modal.Header>
      <Modal.Body>
        <table className="table table-borderless">
          <thead>
            <tr>
              <th>{t("status")}</th>
              <td>{t(`bon_status.${bon.status}`)}</td>
            </tr>
            <tr>
              <th>{t("bon_type")}</th>
              <td>{bon.type !== null ? t(`bon_types.${bon.type}`) : "-"}</td>
            </tr>
            <tr>
              <th>{t("valid_until")}</th>
              <td>
                {bon.valid_until &&
                isAfter(new Date(), new Date(bon.valid_until)) ? (
                  <span className="text-danger">
                    {bon.valid_until}{" "}
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={faCircleExclamation}
                    />
                  </span>
                ) : (
                  <span className="text-success">{bon.valid_until}</span>
                )}
              </td>
            </tr>
            <tr>
              <th>{t("amount")}</th>
              <td>
                {" "}
                {new Intl.NumberFormat("pl", {
                  style: "currency",
                  currency: "PLN",
                }).format(bon.amount)}
              </td>
            </tr>
          </thead>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide(false)}>
          {t("close")}
        </Button>
        <Button variant="primary" onClick={() => onHide(true)}>
          {t("accept")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
