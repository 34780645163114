import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ActivateAccountPropsInterface } from "../authTypes";
import React, { useMemo } from "react";
import { activateAccountRequest } from "../authApi";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";

export const ActivateAccount = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const schema: yup.Schema<ActivateAccountPropsInterface> = useMemo(() => {
    return yup.object({
      password: yup.string().required(t("validations.field_is_required")),
      passwordConfirmation: yup
        .string()
        .required(t("validations.field_is_required"))
        .oneOf(
          [yup.ref("password"), ""],
          t("validations.passwords_must_match")
        ),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ActivateAccountPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const onSubmit = (data: ActivateAccountPropsInterface) => {
    dispatch(
      activateAccountRequest({
        token: token!,
        ...data,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(t("account_activated"));
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch(() => {
        toast.error(t("account_activation_failed"));
      });
  };

  return (
    <React.Fragment>
      <div className="d-flex align-content-center flex-wrap justify-content-center vh-100">
        <div className="sign-in-form" style={{ width: "600px" }}>
          <h1 className="mb-0">{t("activate_account")}</h1>
          <p>{t("activate_account_message")}</p>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label>{t("password")}</Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder={t("placeholders.enter_password")}
                      {...field}
                      isInvalid={!!errors.password}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Label>{t("password_confirmation")}</Form.Label>
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder={t(
                        "placeholders.enter_password_confirmation"
                      )}
                      {...field}
                      isInvalid={!!errors.passwordConfirmation}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passwordConfirmation?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <div className="d-inline-block w-100">
                  <button type="submit" className="btn btn-primary float-right">
                    {t("activate")}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
