import { BonTypeEnum, ChargeBonPropsInterface } from "../../redux/bonTypes";
import React, { useEffect, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type BonChargeProps = {
  onSubmit: (data: ChargeBonPropsInterface) => void;
  resetForm: boolean;
};

export const BonChargeForm = (props: BonChargeProps) => {
  const { t } = useTranslation();
  const { onSubmit, resetForm } = props;

  const schema: yup.Schema<ChargeBonPropsInterface> = useMemo(() => {
    return yup.object({
      document: yup.string().required(t("validations.field_is_required")),
      amount: yup
        .number()
        .required(t("validations.field_is_required"))
        .min(1, t("validations.field_must_be_greater_than", { min: 1 })),
      type: yup
        .mixed<BonTypeEnum>()
        .required(t("validations.field_is_required")),
      expired_months: yup.number().required(t("validations.field_is_required")),
    });
  }, [t]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    reset,
  } = useForm<ChargeBonPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      document: "",
      amount: 0,
      type: BonTypeEnum.FOR_ALL,
      expired_months: 12,
    },
  });

  useEffect(() => {
    if (resetForm) {
      reset();
    }
  }, [resetForm]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} sm={12} md={4} lg={3} className="mb-2">
            <Form.Label>{t("document_number")}</Form.Label>
            <Controller
              name="document"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.document}
                  {...field}
                  placeholder={t("placeholders.enter_document_number")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.document?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6} lg={1}>
            <Form.Label>{t("amount")}</Form.Label>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="number"
                  isInvalid={!!errors.amount}
                  {...field}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.amount?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6} lg={3} className="mb-2">
            <Form.Label>{t("bon_type")}</Form.Label>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Form.Select
                  className="form-control"
                  isInvalid={!!errors.type}
                  {...field}
                >
                  {Object.values(BonTypeEnum)
                    .filter((v) => !isNaN(Number(v)))
                    .map((status, index) => {
                      return (
                        <option key={index} value={status}>
                          {t(`bon_types.${status}`)}
                        </option>
                      );
                    })}
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.type?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg={2} sm={6}>
            <Form.Label>{t("expired_months")}</Form.Label>
            <Controller
              name="expired_months"
              control={control}
              render={({ field }) => (
                <Form.Select
                  className="form-control"
                  isInvalid={!!errors.type}
                  {...field}
                >
                  {[3, 6, 12].map((months, index) => (
                    <option key={index} value={months}>
                      {months}
                    </option>
                  ))}
                </Form.Select>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.type?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button type="submit" variant="primary">
              {t("charge")}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
