import React, { useEffect } from "react";
import { UserForm } from "./parts/form";
import { SaveUserPropsInterface } from "../redux/userTypes";
import { useStoreUserMutation } from "../redux/userApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export const UserCreate = () => {
  const [storeUser, { isSuccess, isError, error }] = useStoreUserMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = (data: SaveUserPropsInterface) => {
    storeUser(data);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(t("user_created_successfully"));
      navigate("/panel/users");
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("creating_new_user")}</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <UserForm isError={isError} error={error} onSubmit={onSubmit} />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
