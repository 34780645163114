import React from "react";
import { ClientNavbar } from "./navbar";
import { Outlet } from "react-router-dom";

export const ClientLayout = () => {
  return (
    <React.Fragment>
      <div className="wrapper">
        <ClientNavbar />
        <div className="content-page">
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
      <footer
        className="bg-white iq-footer"
        style={{ position: "sticky", bottom: 0 }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a href="https://aurum-optics.pl/">Aurum-Optics.pl</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://aurum-optics.pl/karta-podarunkowa-regulamin">
                    Regulamin Karty Podarunkowej
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
