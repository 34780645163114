import { PaginationPropsInterface } from "../../../app/types";
import { bonCardApi } from "./../../../app/bonCardApi";
import {
  GetUserSuccessResponse,
  GetUsersSuccessResponse,
  SaveUserPropsInterface,
  UserInterface,
} from "./userTypes";

export const userApi = bonCardApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersSuccessResponse, PaginationPropsInterface>({
      query: (pagination) => ({
        url: "/api/users",
        params: pagination,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: "Users", id } as const)),
              { type: "Users", id: "LIST" },
            ]
          : [{ type: "Users", id: "LIST" }],
    }),
    getUser: builder.query<UserInterface, number | string>({
      query: (id) => `/api/users/${id}`,
      transformResponse: (response: GetUserSuccessResponse) => response.data,
      providesTags: (result, error, id) => [{ type: "Users", id: id }],
    }),
    storeUser: builder.mutation<void, SaveUserPropsInterface>({
      query: (body) => ({
        url: "/api/users",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    updateUser: builder.mutation<
      void,
      SaveUserPropsInterface & { id: string | number }
    >({
      query: ({ id, ...props }) => ({
        url: `/api/users/${id}`,
        method: "PUT",
        body: props,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Users", id: id }],
    }),
    deleteUser: builder.mutation<void, number | string>({
      query: (id) => ({
        url: `/api/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "Users", id: id }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useStoreUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
