import {
  PaginationInterface,
  SuccessResponseInterface,
} from "../../../app/types";
import { SalonInterface } from "../../salon/redux/salonTypes";

export interface UserInterface {
  id: number;
  email: string;
  name: string;
  surname: string;
  salon_id: number | null;
  salon: SalonInterface | null;
  role: UserRoleEnum;
  status: UserStatusEnum;
}

export enum UserRoleEnum {
  ADMIN = 0,
  EMPLOYER = 1,
}

export enum UserStatusEnum {
  ACTIVE = "active",
  DISABLED = "disabled",
}

export interface GetUsersSuccessResponse extends PaginationInterface {
  items: UserInterface[];
}

export interface GetUserSuccessResponse extends SuccessResponseInterface {
  data: UserInterface;
}

export interface SaveUserPropsInterface
  extends Pick<
    UserInterface,
    "email" | "surname" | "name" | "role" | "salon_id"
  > {}
