import { useAppSelector } from "../app/hooks";
import { getIsAuthorized } from "../features/auth/authSlice";
import { Navigate, Outlet } from "react-router-dom";
import { Layout } from "../features/components/layout/layout";

export const AppRoute = () => {
  const isAuthorized = useAppSelector(getIsAuthorized);

  return isAuthorized ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={"/login"} />
  );
};
