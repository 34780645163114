import React, { useEffect, useMemo, useState } from "react";
import { Loading } from "../../components/loading";
import { useGetBonImportsQuery } from "../redux/bonImportApi";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BonImportInterface } from "../redux/bonImportTypes";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Pagination } from "../../components/pagination";
import { BonImport } from "./import";
import { ActionColumn } from "./parts/actionDropdown";
import { BreadCrumbInterface } from "../../components/breadcrumbs/breadcrumbs.types";
import { useBreadcrumbs } from "../../../app/hooks";

export const BonImportList = () => {
  const [show, setShow] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });
  const {
    data: getBonImportsData,
    isLoading,
    error,
  } = useGetBonImportsQuery({
    per_page: pageSize,
    page: pageIndex + 1,
  });
  const { t } = useTranslation();
  const [data, setData] = useState<BonImportInterface[]>([]);

  useBreadcrumbs(breadcrumbs);

  useEffect(() => {
    if (getBonImportsData) {
      setData(getBonImportsData.items);
    }
  }, [getBonImportsData]);

  const columnHelper = createColumnHelper<BonImportInterface>();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: "id",
        header: "#",
      }),
      columnHelper.accessor((row) => t(`bon_imports_status.${row.status}`), {
        id: "status",
        header: t("status"),
      }),
      columnHelper.display({
        id: "stats_ok",
        header: t("bon_imports_stats_ok"),
        cell: ({ row: { original } }) =>
          original.stats ? (
            <span className="text-success">{original.stats.ok}</span>
          ) : null,
      }),
      columnHelper.display({
        id: "stats_error",
        header: t("bon_imports_stats_error"),
        cell: ({ row: { original } }) =>
          original.stats ? (
            <span className="text-danger">{original.stats.error}</span>
          ) : null,
      }),
      columnHelper.display({
        id: "stats_warning",
        header: t("bon_imports_stats_warning"),
        cell: ({ row: { original } }) =>
          original.stats ? (
            <span className="text-warning">{original.stats.duplicates}</span>
          ) : null,
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => <ActionColumn bonImport={row.original} />,
      }),
    ],
    [columnHelper, t]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: getBonImportsData?.info.total_items,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">{t("bon_imports")}</h4>
              </div>
              <div>
                <Button variant="primary" onClick={() => setShow(!show)}>
                  {t("import")}
                </Button>
              </div>
            </div>
            <div className="iq-card-body">
              <table className="table">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={getBonImportsData?.info.total_pages ?? 0}
                  table={table}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <BonImport show={show} onHide={() => setShow(!show)} />
    </React.Fragment>
  );
};

const breadcrumbs: BreadCrumbInterface[] = [
  {
    url: "/panel/bon-imports",
    label: "menu.import_bons",
  },
];
