import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import React from "react";

type ActionColumnProps = {
  onClick?: () => void;
  showDetailsLink?: string;
  editLink: string;
};

export const ActionColumn = (props: ActionColumnProps) => {
  const { showDetailsLink, onClick, editLink } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-action"
          style={{ background: "none", border: "0", color: "#a6b0cf" }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {showDetailsLink && (
            <Dropdown.Item role={"link"} href={showDetailsLink}>
              {t("show_details")}
            </Dropdown.Item>
          )}
          <Dropdown.Item role={"link"} href={editLink}>
            {t("edit")}
          </Dropdown.Item>
          {onClick && (
            <Dropdown.Item onClick={() => onClick()} role={"button"}>
              {t("delete")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};
