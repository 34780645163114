import { useTranslation } from "react-i18next";
import {
  SalonInterface,
  SaveSalonPropsInterface,
} from "../../redux/salonTypes";
import React, { useEffect, useMemo } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { Col, InputGroup, Row, Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { keysOf } from "../../../../app/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type SalonFormProps = {
  onSubmit: (data: SaveSalonPropsInterface) => void;
  error: any;
  isError: boolean;
  data?: SalonInterface;
};

export const SalonForm = (props: SalonFormProps) => {
  const { t } = useTranslation();
  const { onSubmit, error, isError, data } = props;

  const schema: yup.Schema<SaveSalonPropsInterface> = useMemo(() => {
    return yup.object({
      name: yup.string().required(t("validations.field_is_required")),
      address: yup.string().required(t("validations.field_is_required")),
    });
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm<SaveSalonPropsInterface>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      address: "",
    },
  });

  useEffect(() => {
    if (data) {
      keysOf(data).forEach((key) => {
        if (key === "address" || key === "name") {
          setValue(key, data[key]);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (error && "data" in error && error.status === 422 && error.data) {
      const errorResponse: any = error.data;
      Object.entries(errorResponse.errors).forEach((field) => {
        const parameter = field[0] as keyof SaveSalonPropsInterface;

        setError(parameter, {
          type: "validate",
          message: errorResponse.errors[field[0]][0],
        });
      });
    }
  }, [isError, error, setError]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col} md="2">
            <Form.Label>{t("salon_name")}</Form.Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...field}
                  placeholder={t("placeholders.enter_salon_name")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>{t("address")}</Form.Label>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  isInvalid={!!errors.address}
                  {...field}
                  placeholder={t("placeholders.enter_address")}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.address?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button variant="success" type="submit">
              <FontAwesomeIcon icon={faCheck} /> {t("save")}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
